import { RENOVATION_GLOBAL_AUDIT_QUOTATION_TRANSLATION } from './messages';

export const pagesType = Object.freeze({
    DASHBOARD: 'DASHBOARD',
    ORGANIZATION: 'ORGANIZATION',
    SUB_CONTRACTORS: 'SUB_CONTRACTORS',
    CONTRACTS: 'CONTRACTS',
    PRODUCTS: 'PRODUCTS',
    CUSTOMERS: 'CUSTOMERS',
    QUOTATIONS: 'QUOTATIONS',
    INVOICES: 'INVOICES',
    DELEGATEE_INVOICES: 'DELEGATEE_INVOICES',
    DOSSIERS_CEE: 'DOSSIERS_CEE',
    DOSSIERS_CEE_REGENERATE: 'DOSSIERS_CEE_REGENERATE',
    DOCUMENTS: 'DOCUMENTS',
    PLANNING: 'PLANNING',
    DOSSIERS_MAPRIMERENOV: 'DOSSIERS_MAPRIMERENOV',
    UPLOAD_DOCUMENTS: 'UPLOAD_DOCUMENTS',
    CREATE_CUSTOMER: 'CREATE_CUSTOMER',
    DOSSIERS_FINANCEMENT: 'DOSSIERS_FINANCEMENT',
});

export const roles = Object.freeze({
    ADMIN: 'ADMIN',
    TECHNICIAN: 'TECHNICIAN',
    MA_PRIME_RENOV_REPRESENTATIVE_ACCESS: 'MA_PRIME_RENOV_REPRESENTATIVE_ACCESS',
    OPERATOR: 'OPERATOR',
    RENOLIB_CONTROLLER: 'RENOLIB_CONTROLLER',
});

export const sidebarStates = {
    DEFAULT: 'default',
    EXPANDED: 'sidebar-expanded',
    SHRINKED: 'sidebar-shrinked',
};

export const patchOperations = {
    ADD: 'add',
    UPDATE: 'update',
    SAFEDELETE: 'safe-delete',
    REMOVE: 'remove',
    DELETE: 'delete',
};

export const patchOrganizationPaths = {
    ORGANIZATION: '/organization',
    ADDRESSES: '/organization-addresses',
    CONTACTS: '/organization-contacts',
    BILLING: '/organization-billing',
    RGE_CERTIFICATES: '/organization-rge-certificates',
    INSURANCES: '/organization-insurances',
    KBIS: '/organization-kbis-extracts',
    CONSUMER_MEDIATOR: '/consumer-mediator',
};

export const patchRenovationAddressPaths = {
    HOUSEHOLD_INCOME_BONIFICATION: '/household-income-bonification',
    SOCIAL_LANDLORD_BONIFICATION: '/social-landlord-bonification',
    QPV_BONIFICATION: '/qpv-bonification',
};

export const patchCustomerPMPaths = {
    CUSTOMER_PM: '/',
    ADDRESSES: '/addresses',
    CONTACTS: '/contacts',
};

export const patchSubContractorPaths = {
    SUB_CONTRACTOR: '/sub-contractors',
    ADDRESSES: '/addresses',
    CONTACTS: '/contacts',
    RGE_CERTIFICATES: '/rge-certificates',
};

export const patchContractPaths = {
    CONTRACT: '/contracts',
    CONTRACT_AMENDMENT: '/contract-amendments',
};

export const patchQuotationPaths = {
    CUSTOMER: '/customer',
    ADDRESS: '/address',
    CONTRACT: '/contract',
};

export const organizationInsuranceWarrantyAreas = Object.freeze({
    WHOLE_FRANCE: 'WHOLE_FRANCE',
    METROPOLITAN_FRANCE: 'METROPOLITAN_FRANCE',
    DOM_TOM: 'DOM_TOM',
});

export const organizationInsuranceWarrantyAreasTranslations = Object.freeze({
    WHOLE_FRANCE: 'France entière',
    METROPOLITAN_FRANCE: 'France métropolitaine',
    DOM_TOM: 'DOM-TOM',
});

export const genders = {
    MALE: 'Monsieur',
    FEMALE: 'Madame',
};

export const addressModalTitles = Object.freeze({
    CREATE: 'Nouvelle adresse',
    EDIT: 'Modifier une adresse',
});

export const contactModalTitles = Object.freeze({
    CREATE: 'Ajouter un contact',
    EDIT: 'Modifier un contact',
});

export const renovationAddressHousingTypesTranslations = {
    INDIVIDUAL_HOUSE: 'Maison individuelle',
    APARTMENT: 'Appartement(s)',
};

export const renovationAddressHousingTypes = {
    INDIVIDUAL_HOUSE: 'INDIVIDUAL_HOUSE',
    APARTMENT: 'APARTMENT',
};

export const renovationAddressTypes = {
    HOME: 'Domicile',
    WORK: 'Travaux',
};

export const customerPMActivitiesTranslations = {
    SOCIAL_LANDLORD: 'Bailleur social',
    COOWNED_SYNDICATE: 'Syndic de copropriété',
    OTHER: 'Autre',
};

export const customerPMActivities = {
    SOCIAL_LANDLORD: 'SOCIAL_LANDLORD',
    COOWNED_SYNDICATE: 'COOWNED_SYNDICATE',
    OTHER: 'OTHER',
};

export const renovationAddressBuildingSectorsTranslations = {
    RESIDENTIAL: 'Résidentiel',
    TERTIARY: 'Tertiaire',
};

export const renovationAddressBuildingSectors = {
    RESIDENTIAL: 'RESIDENTIAL',
    TERTIARY: 'TERTIARY',
};

export const renovationAddressActivityAreasTranslations = {
    OFFICES: 'Bureaux',
    EDUCATION: 'Education',
    COMMERCES: 'Commerces',
    HOTELS_CATERING: 'Hôtellerie-Restauration',
    HEALTH: 'Santé',
    COMMUNITY_HOUSING: 'Habitat communautaire',
    SPORTS_FACILITIES: 'Etablissement sportif',
    OTHERS: 'Autres secteurs',
};

export const renovationAddressActivityAreas = {
    OFFICES: 'OFFICES',
    EDUCATION: 'EDUCATION',
    COMMERCES: 'COMMERCES',
    HOTELS_CATERING: 'HOTELS_CATERING',
    HEALTH: 'HEALTH',
    COMMUNITY_HOUSING: 'COMMUNITY_HOUSING',
    SPORTS_FACILITIES: 'SPORTS_FACILITIES',
    OTHERS: 'OTHERS',
};

export const quotationStatuses = Object.freeze({
    DRAFT: 'DRAFT',
    COMPLETED: 'COMPLETED',
    SENT: 'SENT',
    SIGNED: 'SIGNED',
    SIGNED_AND_FINALIZED_RELATED_INVOICE: 'SIGNED_AND_FINALIZED_RELATED_INVOICE',
    EXPIRED: 'EXPIRED',
    REFUSED: 'REFUSED',
});

export const quotationStatusesLabels = Object.freeze({
    DRAFT: 'Brouillon',
    COMPLETED: 'Complété',
    SENT: 'Envoyé',
    SIGNED: 'Accepté',
    SIGNED_AND_FINALIZED_RELATED_INVOICE: 'Facturé',
    EXPIRED: 'Expiré',
    REFUSED: 'Refusé par le client',
});

export const quotationStatusesActionButtonLabels = Object.freeze({
    DRAFT: 'Compléter',
    COMPLETED: 'Modifier',
    SENT: 'Relancer',
    SIGNED: 'Créer la facture',
    SIGNED_AND_FINALIZED_RELATED_INVOICE: 'Consulter',
    EXPIRED: 'Consulter',
    REFUSED: 'Marquer comme "Refusé par le client"',
    SENT_TO_CUSTOMER: 'Marquer comme "Envoyé au client"',
    SEND: 'Envoyer',
    DUPLICATE: 'Dupliquer',
});

export const customerTypes = Object.freeze({
    CUSTOMER_PP: 'CUSTOMER_PP',
    CUSTOMER_PM: 'CUSTOMER_PM',
});

export const customerPMPageActions = Object.freeze({
    CREATE: 'creer',
});

export const customerPageTypes = Object.freeze({
    CUSTOMER_PP: 'particulier',
    CUSTOMER_PM: 'morale',
});

export const administrativeContactRoles = Object.freeze({
    Maire: 'Maire',
    ResponsableHSE: 'Responsable HSE',
    Directeur: 'Directeur',
    Dirigeant: 'Dirigeant',
    Administratif: 'Administratif',
    Financier: 'Financier',
    Commercial: 'Commercial',
    Technicien: 'Technicien',
    SAV: 'SAV',
});

export const inputTypes = Object.freeze({
    DATE: 'date',
});

export const quotationTechnicianTypes = Object.freeze({
    ORGANIZATION: 'ORGANIZATION',
    SUB_CONTRACTOR: 'SUB_CONTRACTOR',
});

export const operationInstallationTypes = Object.freeze({
    COMBLE_PERDU: 'COMBLE_PERDU',
    RAMPANT_DE_TOITURE: 'RAMPANT_DE_TOITURE',
    FENETRES_DE_TOITURE: 'FENETRES_DE_TOITURE',
    TERRASSE_DE_TOITURE: 'TERRASSE_DE_TOITURE',
    AUTRES_FENETRES: 'AUTRES_FENETRES',
    PORTES_FENETRES: 'PORTES_FENETRES',
    COLLECTIVE_INSTALLATION: 'COLLECTIVE_INSTALLATION',
    INDIVIDUAL_INSTALLATION: 'INDIVIDUAL_INSTALLATION',
    AIR_EAU: 'AIR_EAU',
    EAU_EAU: 'EAU_EAU',
    TYPE_A: 'TYPE_A',
    TYPE_B: 'TYPE_B',
    NONE: 'NONE',
    MUR_INTERIEUR: 'MUR_INTERIEUR',
    MUR_EXTERIEUR: 'MUR_EXTERIEUR',
    RESEAU_HYDRAULIQUE_DE_CHAUFFAGE: 'RESEAU_HYDROLIQUE_DE_CHAUFFAGE',
    RESEAU_HYDRAULIQUE_EAU_CHAUDE_SANITAIRE: 'RESEAU_HYDRAULIQUE_EAU_CHAUDE_SANITAIRE',
    AIR_EXTRAIT: 'AIR_EXTRAIT',
    AUTRE_TYPE_INSTALLATION: 'AUTRE_TYPE_INSTALLATION',
    AIR_AMBIANT: 'AIR_AMBIANT',
    SPLIT: 'SPLIT',
    INDIVIDUAL_INSTALLATION_SELF_ADJUSTING: 'INDIVIDUAL_INSTALLATION_SELF_ADJUSTING',
    INDIVIDUAL_INSTALLATION_MODULATED: 'INDIVIDUAL_INSTALLATION_MODULATED',
    COLLECTIVE_INSTALLATION_SELF_ADJUSTING: 'COLLECTIVE_INSTALLATION_SELF_ADJUSTING',
    OIL_TANK_REMOVAL: 'OIL_TANK_REMOVAL',
    DOUBLES_FENETRES: 'DOUBLES_FENETRES',
    EXCLUDING_PLATE_EXCHANGER: 'EXCLUDING_PLATE_EXCHANGER',
    INCLUDING_PLATE_EXCHANGER: 'INCLUDING_PLATE_EXCHANGER',
    INDUCED_WORKS: 'INDUCED_WORKS',
    REMOVAL_OF_OLD_BOILER: 'REMOVAL_OF_OLD_BOILER',
});

export const removalOldBoilerTypes = Object.freeze({
    REMOVAL_OLD_CHARCOAL_BOILER: 'REMOVAL_OLD_CHARCOAL_BOILER',
    REMOVAL_OLD_FUEL_BOILER: 'REMOVAL_OLD_FUEL_BOILER',
    REMOVAL_OLD_GAS_BOILER: 'REMOVAL_OLD_GAS_BOILER',
});

export const operationInstallationTypesTranslations = Object.freeze({
    COMBLE_PERDU: 'Comble perdu',
    RAMPANT_DE_TOITURE: 'Rampant de toiture',
    MUR_INTERIEUR: "Par l'intérieur",
    MUR_EXTERIEUR: "Par l'extérieur",
    FENETRES_DE_TOITURE: 'Fenêtre(s) de toiture',
    AUTRES_FENETRES: 'Autre(s) fenêtre(s)',
    PORTES_FENETRES: 'Porte(s)-fenêtre(s)',
    AIR_EAU: 'Air/eau',
    EAU_EAU: 'Eau/eau',
    COLLECTIVE_INSTALLATION: 'Installation collective',
    INDIVIDUAL_INSTALLATION: 'Installation individuelle',
    TERRASSE_DE_TOITURE: 'Toiture terrasse',
    RESEAU_HYDRAULIQUE_DE_CHAUFFAGE: 'Réseau hydraulique de chauffage',
    RESEAU_HYDRAULIQUE_EAU_CHAUDE_SANITAIRE: "Réseau hydraulique d'eau chaude sanitaire",
    AIR_EXTRAIT: 'Air extrait',
    AUTRE_TYPE_INSTALLATION: 'Autre(s) installation(s)',
    AIR_AMBIANT: 'Air ambiant',
    SPLIT: 'Split',
    INDIVIDUAL_INSTALLATION_SELF_ADJUSTING: 'Installation individuelle autoréglable',
    INDIVIDUAL_INSTALLATION_MODULATED: 'Installation individuelle modulée',
    COLLECTIVE_INSTALLATION_SELF_ADJUSTING: 'Installation collective autoréglable',
    DOUBLES_FENETRES: 'Double(s) fenêtre(s)',
    EXCLUDING_PLATE_EXCHANGER: 'Points singuliers hors échangeurs à plaques',
    INCLUDING_PLATE_EXCHANGER: 'Points singuliers échangeurs à plaques',
});

export const operationInstallationTypesLabels = Object.freeze({
    FENETRES_DE_TOITURE: 'Fenêtres de toiture',
    AUTRES_FENETRES: 'Autres fenêtres',
    PORTES_FENETRES: 'Portes-fenêtres',
    DOUBLES_FENETRES: 'Double(s) fenêtre(s)',
});

export const operationHeatingTypes = Object.freeze({
    COMBUSTIBLE: 'COMBUSTIBLE',
    ELECTRICITE: 'ELECTRICITE',
    UNSPECIFIED: 'UNSPECIFIED',
});

export const operationHeatingTypesLabels = Object.freeze({
    COMBUSTIBLE: 'Combustible',
    ELECTRICITE: 'Électricité',
    'Non renseignée': 'Non renseignée',
});

export const productCategories = Object.freeze({
    ISOLANT: 'Isolant',
    FENETRE: 'Fenêtres, autres fenêtres ou portes-fenêtres',
    CHAUDIERE_INDIVIDUELLE: 'Chaudière individuelle',
    VENTILATION: 'Ventilation',
    POMPE_CHALEUR: 'Pompe à chaleur',
    CALORIFUGEAGE: 'Calorifugeage',
    TOITURE: 'Toiture ou éléments de toiture',
    CHAUFFE_EAU_THERMODYNAMIQUE: 'Chauffe-Eau Thermodynamique',
    CHAUFFE_EAU_SOLAIRE_INDIVIDUEL: 'Chauffe-eau solaire individuel',
    APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS: 'Appareil indépendant de chauffage au bois',
    SYSTEME_SOLAIRE_COMBINE: 'Système solaire combiné',
    CHAUDIERE_BIOMASSE_INDIVIDUELLE: 'Chaudiere biomasse individuelle',
    CHAUFFE_EAU_SOLAIRE: "Chauffe-eau solaire individuel (France d'outre-mer)",
    ECLAIRAGE_PUBLIC: 'Eclairage public (luminaires)',
    PHOTOVOLTAIC_PANELS: 'Panneaux photovoltaïques',
    THERMOSTATIC_VALVE: 'Robinet thermostatique',
    WATER_SAVING_SYSTEMS: 'Systèmes hydro-économes',
    HOUSSE_ISOLANTE: 'Housse isolante',
    PORTE: 'Porte',
    REGULATION_SYSTEM: 'Système de régulation par programmation horaire pièce par pièce',
});

export const productCategoriesValues = Object.freeze({
    ISOLANT: 'ISOLANT',
    POMPE_CHALEUR: 'POMPE_CHALEUR',
    CHAUFFE_EAU_SOLAIRE_INDIVIDUEL: 'CHAUFFE_EAU_SOLAIRE_INDIVIDUEL',
    TOITURE: 'TOITURE',
    FENETRE: 'FENETRE',
    CHAUDIERE_INDIVIDUELLE: 'CHAUDIERE_INDIVIDUELLE',
    VENTILATION: 'VENTILATION',
    CALORIFUGEAGE: 'CALORIFUGEAGE',
    CHAUFFE_EAU_THERMODYNAMIQUE: 'CHAUFFE_EAU_THERMODYNAMIQUE',
    SYSTEME_SOLAIRE_COMBINE: 'SYSTEME_SOLAIRE_COMBINE',
    APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS: 'APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS',
    CHAUDIERE_BIOMASSE_INDIVIDUELLE: 'CHAUDIERE_BIOMASSE_INDIVIDUELLE',
    CHAUFFE_EAU_SOLAIRE: 'CHAUFFE_EAU_SOLAIRE',
    ECLAIRAGE_PUBLIC: 'ECLAIRAGE_PUBLIC',
    PHOTOVOLTAIC_PANELS: 'PHOTOVOLTAIC_PANELS',
    THERMOSTATIC_VALVE: 'THERMOSTATIC_VALVE',
    WATER_SAVING_SYSTEMS: 'WATER_SAVING_SYSTEMS',
    HOUSSE_ISOLANTE: 'HOUSSE_ISOLANTE',
    PORTE: 'PORTE',
    REGULATION_SYSTEM: 'REGULATION_SYSTEM',
});

export const photovoltaicPanelsSubCategories = Object.freeze({
    PHOTOVOLTAIC_PANELS_SUPPLY: 'PHOTOVOLTAIC_PANELS_SUPPLY',
    MICRO_ONDULATOR: 'MICRO_ONDULATOR',
    FIXATION_KIT: 'FIXATION_KIT',
    ONDULATOR: 'ONDULATOR',
});

export const photovoltaicPanelsSubCategoriesTranslation = Object.freeze({
    PHOTOVOLTAIC_PANELS_SUPPLY: 'Fourniture de panneaux photovoltaïques',
    MICRO_ONDULATOR: 'Micro-onduleur',
    FIXATION_KIT: 'Kit de fixation',
    ONDULATOR: 'Onduleur',
});

export const luminaryPublicLightingSubCategories = Object.freeze({
    STREET_LAMP: 'STREET_LAMP',
    PORTHOLE: 'PORTHOLE',
    RECESSED_LIGHTING: 'RECESSED_LIGHTING',
    LANTERNE: 'LANTERN',
    PROJECTOR: 'PROJECTOR',
});

export const luminaryPublicLightingSubCategoriesTranslation = Object.freeze({
    STREET_LAMP: 'Lampadaire',
    PORTHOLE: 'Hublot',
    RECESSED_LIGHTING: 'Luminaire encastré',
    LANTERNE: 'Lanterne',
    PROJECTOR: 'Projecteur',
});

export const luminaryPublicBuldLedTranslation = Object.freeze({
    BULB: 'Ampoule classique',
    LED: 'LED',
});

export const ventilationTypes = Object.freeze({
    TYPE_A: 'TYPE_A',
    TYPE_B: 'TYPE_B',
});

export const ventilationTypesLabels = Object.freeze({
    TYPE_A: 'Type A',
    TYPE_B: 'Type B',
});

export const productVatRates = Object.freeze({
    METROPOLIS_NORMAL_VAT_RATE: 0.2,
    RENOVATION_SPECIFIC_VAT_RATE: 0.1,
    ZNI_NORMAL_VAT_RATE: 0.085,
    METROPOLIS_REDUCED_VAT_RATE: 0.055,
    ZNI_REDUCED_VAT_RATE: 0.021,
    ZERO_VAT_RATE: 0,
});

export const contractPricesTypesEnum = Object.freeze({
    PRECARITY: 'PRECARITY',
    CLASSIC: 'CLASSIC',
    PRECARITY_CDP: 'PRECARITY CDP',
    CLASSIC_CDP: 'CLASSIC CDP',
});

export const contractPriceTypes = Object.freeze({
    CLASSIC: 'CLASSIC',
    PRECARITY: 'PRECARITY',
    PRECARITY_CDP: 'PRECARITY CDP',
    CLASSIC_CDP: 'CLASSIC CDP',
});

export const invoiceStatuses = Object.freeze({
    DRAFT: 'DRAFT',
    FINALIZED: 'FINALIZED',
    SENT: 'SENT',
    PAID: 'PAID',
    CANCELLED: 'CANCELLED',
    RECTIFIED: 'RECTIFIED',
    SENT_FOR_BACK_OFFICE_VERIFICATION: 'SENT_FOR_BACK_OFFICE_VERIFICATION',
    ACQUITTED: 'ACQUITTED',
});

export const invoiceStatusesLabels = Object.freeze({
    DRAFT: 'Brouillon',
    FINALIZED: 'Finalisée',
    SENT: 'Envoyée',
    PAID: 'Payée',
    CANCELLED: 'Annulée',
    RECTIFIED: 'Rectifiée',
    SENT_FOR_BACK_OFFICE_VERIFICATION: 'Envoyée',
    ACQUITTED: 'Acquittée',
});

export const invoiceStatusesActionButtonLabels = Object.freeze({
    DRAFT: 'Supprimer',
    FINALIZED: 'Envoyer',
    SENT: 'Confirmer paiement',
    CANCELLED: 'Consulter',
    PAID: 'Annuler',
    RECTIFIED: 'Consulter',
});

export const dossierCEEStatuses = Object.freeze({
    PROCESSING: 'PROCESSING',
    COMPLETED: 'COMPLETED',
    SENT: 'SENT',
    SENT_FOR_BACK_OFFICE_VERIFICATION: 'SENT_FOR_BACK_OFFICE_VERIFICATION',
    CANCELLED: 'CANCELLED',
    CANCELLED_FROM_BACK_OFFICE: 'CANCELLED_FROM_BACK_OFFICE',
});

export const dossierCEESendingTypes = Object.freeze({
    EMAIL: 'EMAIL',
    MAIL: 'MAIL',
});

export const housingSituations = Object.freeze({
    PRECARITY: 'PRECARITY',
    GREAT_PRECARITY: 'GREAT_PRECARITY',
    CLASSIC: 'CLASSIC',
});

export const housingSituationsTranslation = Object.freeze({
    PRECARITY: 'Modeste',
    GREAT_PRECARITY: 'Précarité énergétique',
    CLASSIC: 'Classique',
});

export const swornStatementsTypes = Object.freeze({
    CADRE_A: 'CADRE_A',
    CADRE_B: 'CADRE_B',
    CADRE_C: 'CADRE_C',
    CADRE_R1: 'CADRE_R1',
    CADRE_R2: 'CADRE_R2',
    CADRE_BS: 'CADRE_BS',
    CADRE_CD: 'CADRE_CD',
    CADRE_QPV: 'CADRE_QPV',
});

export const primeTypes = Object.freeze({
    CEE: 'CEE',
    MPR_CEE: 'CEE_MPR',
    MPR: 'MPR',
});

export const primeDocumentTypes = Object.freeze({
    QUOTATION: 'QUOTATION',
    SWORN_STATEMENT: 'SWORN_STATEMENT',
    CADASTRAL_PLOT: 'CADASTRAL_PLOT',
    POST_RENOVATION_REPORT: 'POST_RENOVATION_REPORT',
    SITE_PHOTO_BEFORE_RENOVATION: 'SITE_PHOTO_BEFORE_RENOVATION',
    SITE_PHOTO_AFTER_RENOVATION: 'SITE_PHOTO_AFTER_RENOVATION',
    SIZING_NOTE: 'SIZING_NOTE',
    SOLAR_FACTOR_CALCULATION_NOTE: 'SOLAR_FACTOR_CALCULATION_NOTE',
    DOMICILIATION_CERTIFICATE: 'DOMICILIATION_CERTIFICATE',
    PROOF_OF_OWNERSHIP: 'PROOF_OF_OWNERSHIP',
    JOINT_OWNER_SHIP_CERTIFICATE: 'JOINT_OWNER_SHIP_CERTIFICATE',
    OWNER_LESSOR_COMMITMENT_CERTIFICATE: 'OWNER_LESSOR_COMMITMENT_CERTIFICATE',
    CLAIM_ASSIGNMENT: 'CLAIM_ASSIGNMENT',
    ATTESTATION_CHANGE_OF_PRODUCT: 'ATTESTATION_CHANGE_OF_PRODUCT',
    ATTESTATION_CHANGE_OF_SUBCONTRACTOR: 'ATTESTATION_CHANGE_OF_SUBCONTRACTOR',
    MOVING_CERTIFICATE: 'MOVING_CERTIFICATE',
    ANAH_GRANT_LETTER: 'ANAH_GRANT_LETTER',
    OTHER: 'OTHER',
    RENOVATION_GLOBAL_ASSISTANCE_CONTRACT_AMO_REFUSE: 'RENOVATION_GLOBAL_ASSISTANCE_CONTRACT_AMO_REFUSE',
    RENOVATION_GLOBAL_COFRAC_BEFORE_WORK: 'RENOVATION_GLOBAL_COFRAC_BEFORE_WORK',
    RENOVATION_GLOBAL_COFRAC_AFTER_WORK: 'RENOVATION_GLOBAL_COFRAC_AFTER_WORK',
    RENOVATION_GLOBAL_ADVISED_OPERATIONS_LIST: 'RENOVATION_GLOBAL_ADVISED_OPERATIONS_LIST',
    COMMISSIONING_REPORT: 'COMMISSIONING_REPORT',
    AUDIT_BEFORE_RENOVATION: 'AUDIT_BEFORE_RENOVATION',
    AUDIT_AFTER_RENOVATION: 'AUDIT_AFTER_RENOVATION',
    RENOVATION_GLOBAL_MAPRIMERENOV_WORKING_STATEMENT: 'RENOVATION_GLOBAL_MAPRIMERENOV_WORKING_STATEMENT',
    RENOVATION_GLOBAL_AUDIT_QUOTATION: 'RENOVATION_GLOBAL_AUDIT_QUOTATION',
    CADRE_CONTRIBUTION: 'CADRE_CONTRIBUTION',
    ATTESTATION_PUBLIC_LIGHTNING: 'ATTESTATION_PUBLIC_LIGHTNING',
    CERTIFICATE_OF_COMPLIANCY: 'CERTIFICATE_OF_COMPLIANCY',
    INVOICE: 'INVOICE',
    TAX_NOTICE: 'TAX_NOTICE',
    SITE_PHOTO: 'SITE_PHOTO',
    SOLAR_CERTIFICATION: 'SOLAR_CERTIFICATION',
    GREEN_FLAME_LABEL_SEVEN: 'GREEN_FLAME_LABEL_SEVEN',
    TECHNICAL_SHEET: 'TECHNICAL_SHEET',
    ADVANCE_PAYMENT_INVOICE: 'ADVANCE_PAYMENT_INVOICE',
    RENOVATION_GLOBAL_AUDIT: 'RENOVATION_GLOBAL_AUDIT',
    RENOVATION_GLOBAL_ASSISTANCE_CONTRACT_AMO: 'RENOVATION_GLOBAL_ASSISTANCE_CONTRACT_AMO',
    RENOVATION_GLOBAL_TECHNICIANS: 'RENOVATION_GLOBAL_TECHNICIANS',
    MAPRIME_RENOV_CERTIFICATE: 'MAPRIME_RENOV_CERTIFICATE',
    TECHNICIAN_STATUS_REPORT: 'TECHNICIAN_STATUS_REPORT',
    RENOVATION_AMPLEUR_ATTESTATION_QUOTATION: 'RENOVATION_AMPLEUR_ATTESTATION_QUOTATION',
    RENOVATION_AMPLEUR_ATTESTATION_INVOICE: 'RENOVATION_AMPLEUR_ATTESTATION_INVOICE',
    SUMMARY_STATEMENT: 'SUMMARY_STATEMENT',
    POST_RENOVATION_CONTROL_REPORT: 'POST_RENOVATION_CONTROL_REPORT',
    DPE_OR_ENERGETIC_AUDIT: 'DPE_OR_ENERGETIC_AUDIT',
    TIME_STAMPED_PHOTO: 'TIME_STAMPED_PHOTO',
    VAT_CERTIFICATE: 'VAT_CERTIFICATE',
    DS_SIGNATURE_CERTIFICATE: 'DS_SIGNATURE_CERTIFICATE',
    BENEFICIARY_CONTROL_FORM: 'BENEFICIARY_CONTROL_FORM',
    PROPERTY_TAX: 'PROPERTY_TAX',
});

export const uploadDocumentTypes = Object.freeze({
    QUOTATION: 'QUOTATION',
    SWORN_STATEMENT: 'SWORN_STATEMENT',
    CADASTRAL_PLOT: 'CADASTRAL_PLOT',
    POST_RENOVATION_REPORT: 'POST_RENOVATION_REPORT',
    SITE_PHOTO_BEFORE_RENOVATION: 'SITE_PHOTO_BEFORE_RENOVATION',
    SITE_PHOTO_AFTER_RENOVATION: 'SITE_PHOTO_AFTER_RENOVATION',
    SIZING_NOTE: 'SIZING_NOTE',
    SOLAR_FACTOR_CALCULATION_NOTE: 'SOLAR_FACTOR_CALCULATION_NOTE',
    DOMICILIATION_CERTIFICATE: 'DOMICILIATION_CERTIFICATE',
    MAPRIME_RENOV_CERTIFICATE: 'MAPRIME_RENOV_CERTIFICATE',
    PROOF_OF_OWNERSHIP: 'PROOF_OF_OWNERSHIP',
    JOINT_OWNER_SHIP_CERTIFICATE: 'JOINT_OWNER_SHIP_CERTIFICATE',
    OWNER_LESSOR_COMMITMENT_CERTIFICATE: 'OWNER_LESSOR_COMMITMENT_CERTIFICATE',
    CLAIM_ASSIGNMENT: 'CLAIM_ASSIGNMENT',
    ATTESTATION_CHANGE_OF_PRODUCT: 'ATTESTATION_CHANGE_OF_PRODUCT',
    ATTESTATION_CHANGE_OF_SUBCONTRACTOR: 'ATTESTATION_CHANGE_OF_SUBCONTRACTOR',
    MOVING_CERTIFICATE: 'MOVING_CERTIFICATE',
    ANAH_GRANT_LETTER: 'ANAH_GRANT_LETTER',
    OTHER: 'OTHER',
    RENOVATION_GLOBAL_ASSISTANCE_CONTRACT_AMO_REFUSE: 'RENOVATION_GLOBAL_ASSISTANCE_CONTRACT_AMO_REFUSE',
    RENOVATION_GLOBAL_COFRAC_BEFORE_WORK: 'RENOVATION_GLOBAL_COFRAC_BEFORE_WORK',
    RENOVATION_GLOBAL_COFRAC_AFTER_WORK: 'RENOVATION_GLOBAL_COFRAC_AFTER_WORK',
    RENOVATION_GLOBAL_ADVISED_OPERATIONS_LIST: 'RENOVATION_GLOBAL_ADVISED_OPERATIONS_LIST',
    COMMISSIONING_REPORT: 'COMMISSIONING_REPORT',
    AUDIT_BEFORE_RENOVATION: 'AUDIT_BEFORE_RENOVATION',
    AUDIT_AFTER_RENOVATION: 'AUDIT_AFTER_RENOVATION',
    RENOVATION_GLOBAL_MAPRIMERENOV_WORKING_STATEMENT: 'RENOVATION_GLOBAL_MAPRIMERENOV_WORKING_STATEMENT',
    RENOVATION_GLOBAL_AUDIT_QUOTATION: 'RENOVATION_GLOBAL_AUDIT_QUOTATION',
    CADRE_CONTRIBUTION: 'CADRE_CONTRIBUTION',
    ATTESTATION_PUBLIC_LIGHTNING: 'ATTESTATION_PUBLIC_LIGHTNING',
    CERTIFICATE_OF_COMPLIANCY: 'CERTIFICATE_OF_COMPLIANCY',
    TAX_NOTICE: 'TAX_NOTICE',
    TECHNICIAN_STATUS_REPORT: 'TECHNICIAN_STATUS_REPORT',
    DPE_OR_ENERGETIC_AUDIT: 'DPE_OR_ENERGETIC_AUDIT',
    SUMMARY_STATEMENT: 'SUMMARY_STATEMENT',
    POST_RENOVATION_CONTROL_REPORT: 'POST_RENOVATION_CONTROL_REPORT',
    TIME_STAMPED_PHOTO: 'TIME_STAMPED_PHOTO',
    VAT_CERTIFICATE: 'VAT_CERTIFICATE',
    PROPERTY_TAX: 'PROPERTY_TAX',
});

export const uploadDocumentTypesTranslations = Object.freeze({
    QUOTATION: 'Devis',
    SWORN_STATEMENT: "Attestation sur l'honneur",
    POST_RENOVATION_REPORT: 'Rapport post-travaux',
    CADASTRAL_PLOT: 'Parcelle cadastrale',
    SITE_PHOTO: 'Photo du site',
    SITE_PHOTO_BEFORE_RENOVATION: 'Photo du site avant travaux',
    SITE_PHOTO_AFTER_RENOVATION: 'Photo du site après travaux',
    SIZING_NOTE: 'Note de dimensionnement',
    SOLAR_FACTOR_CALCULATION_NOTE: 'Note de calcul du facteur solaire',
    DOMICILIATION_CERTIFICATE: 'Attestation de domiciliation',
    MAPRIME_RENOV_CERTIFICATE: "Mandat MaPrimeRénov' signé",
    PROOF_OF_OWNERSHIP: 'Justificatif de propriété',
    JOINT_OWNER_SHIP_CERTIFICATE: "Attestation d'indivision",
    OWNER_LESSOR_COMMITMENT_CERTIFICATE: "Attestation d'engagement propriétaire bailleur",
    CLAIM_ASSIGNMENT: 'Cession de créance signée',
    ATTESTATION_CHANGE_OF_PRODUCT: 'Attestation de changement de produit',
    ATTESTATION_CHANGE_OF_SUBCONTRACTOR: 'Attestation de changement de sous-traitant',
    MOVING_CERTIFICATE: 'Attestation de déménagement',
    ANAH_GRANT_LETTER: 'Lettre d’octroi de l’ANAH',
    OTHER: 'Autre',
    RENOVATION_GLOBAL_ASSISTANCE_CONTRACT_AMO_REFUSE: "Refus exprès de la prestation d'AMO",
    RENOVATION_GLOBAL_COFRAC_BEFORE_WORK: "Rapport Cofrac d'avant travaux",
    RENOVATION_GLOBAL_COFRAC_AFTER_WORK: 'Rapport Cofrac après travaux',
    RENOVATION_GLOBAL_ADVISED_OPERATIONS_LIST: 'Liste des travaux préconisés',
    RENOVATION_GLOBAL_TECHNICIANS: 'Liste des entreprises',
    COMMISSIONING_REPORT: 'Rapport de mise en service',
    AUDIT_BEFORE_RENOVATION: 'Audit énergétique avant travaux',
    AUDIT_AFTER_RENOVATION: 'Audit énergétique après travaux',
    RENOVATION_GLOBAL_MAPRIMERENOV_WORKING_STATEMENT: 'Attestation de travaux / Demande de prime',
    RENOVATION_GLOBAL_AUDIT_QUOTATION: RENOVATION_GLOBAL_AUDIT_QUOTATION_TRANSLATION,
    ATTESTATION_OF_PHOTOVOLTAIC_PANEL: 'Mandat panneau photovoltaïque',
    CADRE_CONTRIBUTION: 'Cadre de contribution',
    ATTESTATION_PUBLIC_LIGHTNING: 'Attestation éclairage public',
    CERTIFICATE_OF_COMPLIANCY: 'Attestation de mise en conformité',
    TAX_NOTICE: 'Avis d’imposition',
    TECHNICIAN_STATUS_REPORT: 'Avis de situation(s) Sirene - base INSEE',
    RENOVATION_AMPLEUR_ATTESTATION_QUOTATION: 'Attestation de travaux (devis)',
    RENOVATION_AMPLEUR_ATTESTATION_INVOICE: 'Attestation de travaux (facture)',
    SUMMARY_STATEMENT: 'État récapitulatif',
    POST_RENOVATION_CONTROL_REPORT: 'Rapport de contrôle post-travaux',
    DPE_OR_ENERGETIC_AUDIT: 'DPE/Audit énergétique',
    TIME_STAMPED_PHOTO: 'Photos horodatées',
    VAT_CERTIFICATE: 'Attestation TVA',
    PROPERTY_TAX: 'Taxe foncière',
});

export const otherDocumentTypes = Object.freeze({
    SOLAR_CERTIFICATION: 'SOLAR_CERTIFICATION',
});

export const uploadDocumentFileTypes = Object.freeze({
    PDF: 'PDF',
    IMAGE: 'IMAGE',
    URL: 'URL',
});

export const productCreationOriginTypes = Object.freeze({
    QUOTATION: 'QUOTATION',
    INVOICE: 'INVOICE',
});

export const paymentTypesEnum = Object.freeze({
    BANK_TRANSFER: 'BANK_TRANSFER',
    BANK_CHECK: 'BANK_CHECK',
    CASH: 'CASH',
});

export const paymentTypesTranslations = Object.freeze({
    CASH: 'Espèces',
    BANK_CHECK: 'Chèque',
    BANK_TRANSFER: 'Virement bancaire',
});

export const productTypes = Object.freeze({
    MAIN_PRODUCT: 'MAIN_PRODUCT',
    INDUCED_WORK_PRODUCT: 'INDUCED_WORK_PRODUCT',
    OTHER_PRODUCT: 'OTHER_PRODUCT',
    NON_ENERGY_RENOVATION_OPERATION_MAIN_PRODUCT: 'NON_ENERGY_RENOVATION_OPERATION_MAIN_PRODUCT',
});

export const productTypeTranslations = {
    MAIN_PRODUCT: 'Produit de rénovation énergétique',
    OTHER_PRODUCT: 'Autre produit ou service',
    NON_ENERGY_RENOVATION_OPERATION_MAIN_PRODUCT: 'Autre travaux',
};

export const unitTypes = Object.freeze({
    SQUARE_METER: 'SQUARE_METER',
    METER: 'METER',
    LITER: 'LITER',
    UNIT: 'UNIT',
    CUBIC_METER: 'CUBIC_METER',
    PACKAGE: 'PACKAGE',
});

export const productUnitsLabelEnum = Object.freeze({
    METER: 'm',
    SQUARE_METER: 'm²',
    CUBIC_METER: 'm³',
    LITER: 'litres',
    UNIT: 'unités',
    PACKAGE: 'forfait',
});

export const productUnitsLabelEnumSingular = Object.freeze({
    METER: 'm',
    SQUARE_METER: 'm²',
    CUBIC_METER: 'm³',
    LITER: 'litre',
    UNIT: 'unité',
    PACKAGE: 'forfait',
});

export const heatPumpTypesLabel = Object.freeze({
    'medium-high-temperature-heat-pump': 'Pompe à chaleur moyenne ou haute température',
    'low-temperature-heat-pump': 'Pompe à chaleur basse température',
});

export const heatingPumpFunctioningTypes = Object.freeze({
    MEDIUM_AND_HIGH_TEMPERATURE: 'medium-high-temperature-heat-pump',
    LOW_TEMPERATURE: 'low-temperature-heat-pump',
});

export const ventilationBoxTypes = Object.freeze({
    STANDARD: 'Ventilation mécanique avec caisson standard',
    LOW_CONSUMPTION: 'Ventilation mécanique avec caisson basse consommation',
    LOW_PRESSURE: 'Ventilation mécanique avec caisson basse pression',
});

export const uploadedDocumentStatuses = Object.freeze({
    PENDING: 'PENDING',
    CONFIRMED: 'CONFIRMED',
    REJECTED: 'REJECTED',
    EXPIRED: 'EXPIRED',
    CANCELLED_FROM_BACK_OFFICE: 'CANCELLED_FROM_BACK_OFFICE',
});

export const uploadedDocumentStatusesLabels = Object.freeze({
    PENDING: 'Vérification',
    CONFIRMED: 'Validé',
    REJECTED: 'Rejeté',
    EXPIRED: 'Expiré',
    CANCELLED_FROM_BACK_OFFICE: 'Annulé',
});

export const sendableDocumentTypes = Object.freeze({
    QUOTATION: 'QUOTATION',
    INVOICE: 'INVOICE',
    DELEGATEE_INVOICE: 'DELEGATEE_INVOICE',
    EMAIL: 'EMAIL',
    CLAIM_ASSIGNMENT: 'CLAIM_ASSIGNMENT',
    TIME_STAMPED_PHOTO_DEEPLINK: 'TIME_STAMPED_PHOTO_DEEPLINK',
    VAT_CERTIFICATE: 'VAT_CERTIFICATE',
    SWORN_STATEMENT: 'SWORN_STATEMENT',
    BENEFICIARY_CONTROL_FORM: 'BENEFICIARY_CONTROL_FORM',
});

export const dossierCEESidebarDocumentFileTypes = Object.freeze({
    PDF: 'PDF',
    PDFS: 'PDFS',
    IMAGE: 'IMAGE',
    IMAGES: 'IMAGES',
    EMPTY: 'EMPTY',
});

export const dossierCEESidebarDocumentBlockTypes = Object.freeze({
    TAX_NOTICES: 'TAX_NOTICES',
});

export const invitationStatuses = Object.freeze({
    SENT: 'SENT',
    ACCEPTED: 'ACCEPTED',
    CONFIRMED: 'CONFIRMED',
});

export const invitationStatusesTranslation = Object.freeze({
    SENT: 'Envoyée',
    ACCEPTED: 'Acceptée',
    CONFIRMED: 'Confirmée',
});

export const maPrimeRenovTypes = Object.freeze({
    BLUE: 'BLUE',
    YELLOW: 'YELLOW',
    PURPLE: 'PURPLE',
    PINK: 'PINK',
    NONE: 'NONE',
});

export const maPrimeRenovLabels = Object.freeze({
    BLUE: 'Bleu',
    YELLOW: 'Jaune',
    PURPLE: 'Violet',
    PINK: 'Rose',
});

export const applicationPages = Object.freeze({
    CUSTOMER_PM_CREATION: 'CUSTOMER_PM_CREATION',
    CUSTOMER_PP_CREATION: 'CUSTOMER_PP_CREATION',
    CUSTOMER: 'CUSTOMER',
    DASHBOARD: 'DASHBOARD',
    DOSSIER_CEE: 'DOSSIER_CEE',
    DOSSIERS_CEE: 'DOSSIERS_CEE',
    INVOICE_EDITION: 'INVOICE_EDITION',
    INVOICE: 'INVOICE',
    INVOICES: 'INVOICES',
    ORGANIZATION: 'ORGANIZATION',
    PRODUCT_CREATION: 'PRODUCT_CREATION',
    QUOTATION_EDITION: 'QUOTATION_EDITION',
    QUOTATION: 'QUOTATION',
    QUOTATIONS: 'QUOTATIONS',
    SUB_CONTRACTOR: 'SUB_CONTRACTOR',
    UPLOAD_DOCUMENT: 'UPLOAD_DOCUMENT',
});

export const dateUnits = Object.freeze({
    DAY: 'day',
    MILLISECONDS: 'milliseconds',
});

export const contractSectors = Object.freeze({
    RESIDENTIAL: 'Résidentiel',
    TERTIARY: 'Tertiaire',
    INDUSTRY: 'Industrie',
    AGRICULTURE: 'Agirculture',
    NETWORK: 'Réseau',
    TRANSPORT: 'Transport',
});

export const contractCoupDePouce = Object.freeze({
    ISOLANT: 'isolation',
    POMPE_CHALEUR: 'chauffage',
    EFFICIENT_RENOVATION_OF_INDIVIDUAL_HOUSES: "rénovation performante d'une maison individuelle",
    EFFICIENT_RENOVATION_OF_COLLECTIVE_RESIDENTIAL_BUILDINGS: 'rénovation performante de bâtiment résidentiel collectif',
    THERMOSTAT_WITH_HIGH_PERFORMANCE_CONTROL: 'thermostat avec régulation performante',
});

export const dossierCEEDocumentTypes = Object.freeze({
    QUOTATION: 'QUOTATION',
    INVOICE: 'INVOICE',
    TAX_NOTICE: 'TAX_NOTICE',
    SWORN_STATEMENT: 'SWORN_STATEMENT',
    CADASTRAL_PLOT: 'CADASTRAL_PLOT',
    POST_RENOVATION_REPORT: 'POST_RENOVATION_REPORT',
    SITE_PHOTO: 'SITE_PHOTO',
    SITE_PHOTO_BEFORE_RENOVATION: 'SITE_PHOTO_BEFORE_RENOVATION',
    SITE_PHOTO_AFTER_RENOVATION: 'SITE_PHOTO_AFTER_RENOVATION',
    CADRE_CONTRIBUTION: 'CADRE_CONTRIBUTION',
    SIZING_NOTE: 'SIZING_NOTE',
    SOLAR_CERTIFICATION: 'SOLAR_CERTIFICATION',
    SOLAR_FACTOR_CALCULATION_NOTE: 'SOLAR_FACTOR_CALCULATION_NOTE',
    DOMICILIATION_CERTIFICATE: 'DOMICILIATION_CERTIFICATE',
    OTHER: 'OTHER',
    GREEN_FLAME_LABEL_SEVEN: 'GREEN_FLAME_LABEL_SEVEN',
    TECHNICAL_SHEET: 'TECHNICAL_SHEET',
    ADVANCE_PAYMENT_INVOICE: 'ADVANCE_PAYMENT_INVOICE',
    MOVING_CERTIFICATE: 'MOVING_CERTIFICATE',
    CLAIM_ASSIGNMENT: 'CLAIM_ASSIGNMENT',
    RENOVATION_GLOBAL_AUDIT: 'RENOVATION_GLOBAL_AUDIT',
    RENOVATION_GLOBAL_ASSISTANCE_CONTRACT_AMO: 'RENOVATION_GLOBAL_ASSISTANCE_CONTRACT_AMO',
    RENOVATION_GLOBAL_ASSISTANCE_CONTRACT_AMO_REFUSE: 'RENOVATION_GLOBAL_ASSISTANCE_CONTRACT_AMO_REFUSE',
    RENOVATION_GLOBAL_COFRAC_BEFORE_WORK: 'RENOVATION_GLOBAL_COFRAC_BEFORE_WORK',
    RENOVATION_GLOBAL_COFRAC_AFTER_WORK: 'RENOVATION_GLOBAL_COFRAC_AFTER_WORK',
    RENOVATION_GLOBAL_ADVISED_OPERATIONS_LIST: 'RENOVATION_GLOBAL_ADVISED_OPERATIONS_LIST',
    RENOVATION_GLOBAL_TECHNICIANS: 'RENOVATION_GLOBAL_TECHNICIANS',
    RENOVATION_GLOBAL_MAPRIMERENOV_WORKING_STATEMENT: 'RENOVATION_GLOBAL_MAPRIMERENOV_WORKING_STATEMENT',
    COMMISSIONING_REPORT: 'COMMISSIONING_REPORT',
    AUDIT_BEFORE_RENOVATION: 'AUDIT_BEFORE_RENOVATION',
    AUDIT_AFTER_RENOVATION: 'AUDIT_AFTER_RENOVATION',
    RENOVATION_GLOBAL_AUDIT_QUOTATION: 'RENOVATION_GLOBAL_AUDIT_QUOTATION',
    ATTESTATION_PUBLIC_LIGHTNING: 'ATTESTATION_PUBLIC_LIGHTNING',
    CERTIFICATE_OF_COMPLIANCY: 'CERTIFICATE_OF_COMPLIANCY',
    SUMMARY_STATEMENT: 'SUMMARY_STATEMENT',
    POST_RENOVATION_CONTROL_REPORT: 'POST_RENOVATION_CONTROL_REPORT',
    TIME_STAMPED_PHOTO: 'TIME_STAMPED_PHOTO',
});

export const dossierCEEDocumentTypesOnlyForFrontDistinction = {
    ADD_OTHER_DOCUMENT_BUTTON: 'ADD_OTHER_DOCUMENT_BUTTON',
    TECHNICAL_PRODUCT_SHEET: 'Fiche technique produit(s)',
    TECHNICIAN_STATUS_REPORT: 'Avis de situation(s) Sirene - base INSEE',
};
export const taxHouseholdFormEntities = Object.freeze({
    CUSTOMER: 'CUSTOMER',
    OCCUPANT: 'OCCUPANT',
});

export const taxHouseholdFormModes = Object.freeze({
    SEARCH: 'SEARCH',
    MANUAL: 'MANUAL',
    MANUAL_TAX_INFORMATION: 'MANUAL_TAX_INFORMATION',
});

export const formModes = Object.freeze({
    CREATION: 'CREATION',
    EDITION: 'EDITION',
});

export const sidebarMenuRoutes = Object.freeze({
    CONTRACTS: '/contrats',
    INVOICES: '/factures',
    UPLOAD_DOCUMENTS: '/documents',
});

export const sidebarMenuTranslations = Object.freeze({
    CONTRACTS: 'Contrats CEE',
    INVOICES: 'Factures clients',
    UPLOAD_DOCUMENTS: 'Ajout de documents',
});

export const exportDataTypes = Object.freeze({
    INVOICE: 'INVOICE',
    DOSSIER: 'DOSSIER',
    QUOTATION: 'QUOTATION',
    CUSTOMER: 'CUSTOMER',
});

export const exportTypes = Object.freeze({
    CSV: 'CSV',
    PDF: 'PDF',
});

export const operationInstallationFinishes = Object.freeze({
    PLACO: 'PLACO',
    ENDUIT: 'ENDUIT',
    BARDAGE: 'BARDAGE',
    PAREMENT: 'PAREMENT',
    AUTRE: 'AUTRE',
});

export const operationInstallationFinishesTranslations = Object.freeze({
    PLACO: 'Placo',
    ENDUIT: 'Enduit',
    BARDAGE: 'Bardage',
    PAREMENT: 'Parement',
    AUTRE: 'Autre',
});

export const documentTypes = Object.freeze({
    QUOTATION: 'QUOTATION',
    INVOICE: 'INVOICE',
    DOSSIERS_CEE: 'DOSSIERS_CEE',
    DELEGATEE_INVOICE: 'DELEGATEE_INVOICE',
    EMAIL: 'EMAIL',
});

export const buildingAgeValue = Object.freeze({
    NEW_BUILDING: 'NEW_BUILDING',
    BUILDING_IS_OLDER_THAN_TWO_YEARS: 'BUILDING_IS_OLDER_THAN_TWO_YEARS',
    BUILDING_IS_OLDER_THAN_FIFTEEN_YEARS: 'BUILDING_IS_OLDER_THAN_FIFTEEN_YEARS',
});
export const buildingAgeTranslations = Object.freeze({
    NEW_BUILDING: 'Moins de 2 ans',
    BUILDING_IS_OLDER_THAN_TWO_YEARS: 'Plus de 2 ans',
    BUILDING_IS_OLDER_THAN_FIFTEEN_YEARS: 'Plus de 15 ans',
});

export const buildingAgeTypes = Object.freeze({
    NEW_BUILDING: 'NEW_BUILDING',
    BUILDING_IS_OLDER_THAN_TWO_YEARS: 'BUILDING_IS_OLDER_THAN_TWO_YEARS',
    BUILDING_IS_OLDER_THAN_FIFTEEN_YEARS: 'BUILDING_IS_OLDER_THAN_FIFTEEN_YEARS',
});

export const contractStatuses = Object.freeze({
    PENDING: 'PENDING',
    PENDING_SIGNATURE: 'PENDING_SIGNATURE',
    CONFIRMED: 'CONFIRMED',
    REJECTED: 'REJECTED',
    EXPIRED: 'EXPIRED',
    CANCELLED_FROM_BACK_OFFICE: 'CANCELLED_FROM_BACK_OFFICE',
    DISABLED: 'DISABLED',
});

export const contractStatusesTranslation = Object.freeze({
    PENDING: 'Vérification',
    PENDING_SIGNATURE: 'En attente de signature',
    CONFIRMED: 'Validé',
    REJECTED: 'Rejeté',
    EXPIRED: 'Expiré',
    CANCELLED_FROM_BACK_OFFICE: 'Annulé',
    DISABLED: 'Inactif',
});

export const contractAmendmentStatus = Object.freeze({
    PENDING: 'PENDING',
    CONFIRMED: 'CONFIRMED',
    REJECTED: 'REJECTED',
    EXPIRED: 'EXPIRED',
});

export const contractAmendmentStatusTranslation = Object.freeze({
    PENDING: 'Vérification',
    CONFIRMED: 'Validé',
    REJECTED: 'Rejeté',
    CANCELLED_FROM_BACK_OFFICE: 'Annulé',
    EXPIRED: 'Expiré',
});

export const categoryTypes = Object.freeze({
    PRIMARY: 'PRIMARY',
    SECONDARY: 'SECONDARY',
});

export const categoryColors = Object.freeze({
    0: '#A0A5BA',
    1: '#E46526',
    2: '#6E52BC',
    3: '#00928F',
});

export const appointmentStatuses = Object.freeze({
    SENT: 'SENT',
    ACCEPTED: 'ACCEPTED',
    IN_PROGRESS: 'IN_PROGRESS',
    ENDED: 'ENDED',
    REJECTED: 'REJECTED',
    CANCELLED: 'CANCELLED',
    WITHOUT_CUSTOMER: 'WITHOUT_CUSTOMER',
});

export const fileTypes = Object.freeze({
    NEW: 'NEW',
    EXISTING: 'EXISTING',
});

export const appointmentModalTypes = Object.freeze({
    UPDATE: 'UPDATE',
    CREATE: 'CREATE',
    READ: 'READ',
});

export const appointmentFileTypes = Object.freeze({
    TECHNICIAN: 'TECHNICIAN',
    CUSTOMER: 'CUSTOMER',
});

export const reminderOptions = Object.freeze({
    ONE_HOUR: 'ONE_HOUR',
    ONE_DAY: 'ONE_DAY',
    TWO_DAYS: 'TWO_DAYS',
});

export const personStatus = Object.freeze({
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
    SENT: 'SENT',
});

export const notificationTokenStatus = Object.freeze({
    ALREADY_ACCEPTED: 'ALREADY_ACCEPTED',
    ALREADY_REJECTED: 'ALREADY_REJECTED',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
    EXPIRED: 'EXPIRED',
});

export const appointmentNotifiedAccountsEnum = Object.freeze({
    SMS: 'SMS',
    EMAIL: 'EMAIL',
    SMS_AND_EMAIL: 'SMS_AND_EMAIL',
    NOT_SPECIFIED: 'NOT_SPECIFIED',
});

export const statusesInfo = Object.freeze({
    SENT: {
        title: 'Envoyé',
        color: '#E46526',
    },
    ACCEPTED: {
        title: 'Accepté',
        color: '#00928F',
    },
    IN_PROGRESS: {
        title: 'En cours',
        color: '#102339',
    },
    ENDED: {
        title: 'Terminé',
        color: '#99A3AC',
    },
    REJECTED: {
        title: 'Rejeté',
        color: '#F53A6B',
    },
    CANCELLED: {
        title: 'Annulé',
        color: '#F53A6B',
    },
    WITHOUT_CUSTOMER: {
        title: 'Sans client',
        color: '#102339',
    },
});

export const tooltipVisibilityType = Object.freeze({
    HIDDEN: 'hidden',
    SHOWN: 'shown',
});

export const planningPageTypes = Object.freeze({
    APPOINTMENTS: 'appointments',
    CATEGORIES: 'categories',
    CALENDAR: 'calendar',
});

export const cancelledDocumentDocumentTypeTranslation = Object.freeze({
    CONTRACT: 'contrat ',
    CONTRACT_AMENDMENT: "l'avenant ",
    RGE_CERTIFICATE: 'certificat RGE ',
    RGE_CERTIFICATE_OF_SUBCONTRACTOR: 'certificat RGE ',
});

export const documentsToVerify = Object.freeze({
    CONTRACT: 'CONTRACT',
    RGE_CERTIFICATE: 'RGE_CERTIFICATE',
    RGE_CERTIFICATE_OF_SUBCONTRACTOR: 'RGE_CERTIFICATE_OF_SUBCONTRACTOR',
    CONTRACT_AMENDMENT: 'CONTRACT_AMENDMENT',
});

export const representativeType = Object.freeze({
    ADMINISTRATIVE_REPRESENTATIVE: 'ADMINISTRATIVE_REPRESENTATIVE',
    ADMINISTRATIVE_AND_FINANCIAL_REPRESENTATIVE: 'ADMINISTRATIVE_AND_FINANCIAL_REPRESENTATIVE',
    FINANCIAL_REPRESENTATIVE: 'FINANCIAL_REPRESENTATIVE',
});

export const representativeTypeTranslation = Object.freeze({
    ADMINISTRATIVE_REPRESENTATIVE: 'Administratif',
    ADMINISTRATIVE_AND_FINANCIAL_REPRESENTATIVE: 'Mixte',
});

export const mixedMandateStatusEnums = Object.freeze({
    PENDING: 'PENDING',
    CONFIRMED: 'CONFIRMED',
    REJECTED: 'REJECTED',
});

export const productSubCategories = Object.freeze({
    POMPE_CHALEUR: {
        POMPE_CHALEUR_AIR_EAU: 'POMPE_CHALEUR_AIR_EAU',
        POMPE_CHALEUR_EAU_EAU: 'POMPE_CHALEUR_EAU_EAU',
        POMPE_CHALEUR_HYBRIDE: 'POMPE_CHALEUR_HYBRIDE',
        POMPE_CHALEUR_AIR_AIR: 'POMPE_CHALEUR_AIR_AIR',
        POMPE_CHALEUR_SOL_EAU: 'POMPE_CHALEUR_SOL_EAU',
    },
    APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS: {
        POELE_GRANULES: 'POELE_GRANULES',
        POELE_BUCHES: 'POELE_BUCHES',
        FOYER_FERME_OU_INSERT_CHEMINEE_EXTERIEURE: 'FOYER_FERME_OU_INSERT_CHEMINEE_EXTERIEURE',
        CUISINIERE_UTILISEE_COMME_MODE_DE_CHAUFFAGE: 'CUISINIERE_UTILISEE_COMME_MODE_DE_CHAUFFAGE',
    },
    CHAUDIERE: {
        CHAUDIERE_BIOMASSE_INDIVIDUELLE: 'CHAUDIERE_BIOMASSE_INDIVIDUELLE',
        CHAUDIERE_INDIVIDUELLE_A_HAUTE_PERFORMANCE_ENERGETIQUE: 'CHAUDIERE_INDIVIDUELLE',
    },
    VENTILATION: {
        SIMPLE_FLOW_VENTILATION: 'SIMPLE_FLOW_VENTILATION',
        DOUBLE_FLOW_VENTILATION: 'DOUBLE_FLOW_VENTILATION',
    },
    PHOTOVOLTAIC_PANELS: {
        ...photovoltaicPanelsSubCategories,
    },
    WATER_SAVING_SYSTEMS: {
        SHOWER_HEAD: 'SHOWER_HEAD',
        JET_REGULATOR: 'JET_REGULATOR',
    },
});

export const productSubCategoriesTranslations = Object.freeze({
    POMPE_CHALEUR_AIR_EAU: 'Pompe à chaleur air/eau',
    POMPE_CHALEUR_EAU_EAU: 'Pompe à chaleur eau/eau',
    POMPE_CHALEUR_HYBRIDE: 'Pompe à chaleur hybride',
    POMPE_CHALEUR_AIR_AIR: 'Pompe à chaleur air/air',
    POMPE_CHALEUR_SOL_EAU: 'Pompe à chaleur sol/eau',
    POELE_GRANULES: 'Poêle à granulés',
    POELE_BUCHES: 'Poêle à bûches',
    FOYER_FERME_OU_INSERT_CHEMINEE_EXTERIEURE: 'Foyer fermé, insert de cheminée intérieure',
    CHAUDIERE_BIOMASSE_INDIVIDUELLE: 'Chaudière biomasse individuelle',
    CHAUDIERE_INDIVIDUELLE: 'Chaudière individuelle à haute performance énergétique',
    SIMPLE_FLOW_VENTILATION: 'Ventilation simple flux',
    DOUBLE_FLOW_VENTILATION: 'Ventilation double flux',
    CUISINIERE_UTILISEE_COMME_MODE_DE_CHAUFFAGE: 'Cuisinière utilisée comme mode de chauffage',
    ...photovoltaicPanelsSubCategoriesTranslation,
    SHOWER_HEAD: 'Pomme de douche',
    JET_REGULATOR: 'Régulateur de jet',
});

export const productSheetMetalColors = Object.freeze({
    LIGHT: 'LIGHT',
    MEDIUM: 'MEDIUM',
    DARK: 'DARK',
    BLACK: 'BLACK',
});

export const productSheetMetalColorsTranslations = Object.freeze({
    LIGHT: 'Couleur claire',
    MEDIUM: 'Couleur moyenne',
    DARK: 'Couleur sombre',
    BLACK: 'Couleur noire',
});

export const productSheetMetalColorsTranslationsAdditonalComments = Object.freeze({
    LIGHT: 'blanc, jaune, orange, rouge clair',
    MEDIUM: 'bleu clair, gris clair, rouge sombre, vert clair',
    DARK: 'bleu vif, brun, gris moyen, vert sombre, lasure sur bois',
    BLACK: 'gris sombre, brun sombre, bleu sombre',
});

export const energyEfficiencyUnits = Object.freeze({
    THERMAL_RESISTANCE: 'm².K/W',
    THICKNESS: 'mm',
    LAMBDA: 'W/(m.K)',
});

export const fluidOptionsEnums = Object.freeze({
    EAU: 'EAU',
    EAU_GLYCOLEE: 'EAU_GLYCOLEE',
    AIR: 'AIR',
});

export const productStatusesTranslation = Object.freeze({
    PENDING: 'Vérification',
    CONFIRMED: 'Validé',
    UNVERIFIED: 'Non vérifié',
    WITHOUT_VERIFICATION: 'Sans vérification',
    REJECTED: 'Rejeté',
});

export const productStatuses = Object.freeze({
    PENDING: 'PENDING',
    CONFIRMED: 'CONFIRMED',
    UNVERIFIED: 'UNVERIFIED',
    WITHOUT_VERIFICATION: 'WITHOUT_VERIFICATION',
    REJECTED: 'REJECTED',
});

export const productLayerTwoThicknesses = Object.freeze({
    FIVE: 'FIVE',
    SEVEN: 'SEVEN',
    TEN: 'TEN',
    FIFTEEN: 'FIFTEEN',
    BETWEEN_TWENTY_FIVE_AND_FIFTY: 'BETWEEN_TWENTY_FIVE_AND_FIFTY',
    BETWEEN_FIFTY_AND_ONE_HUNDRED: 'BETWEEN_FIFTY_AND_ONE_HUNDRED',
    BETWEEN_ONE_HUNDRED_AND_THREE_HUNDRED: 'BETWEEN_ONE_HUNDRED_AND_THREE_HUNDRED',
    THREE_HUNDRED: 'THREE_HUNDRED',
});

export const productLayerTwoThicknessesTranslations = Object.freeze({
    FIVE: 'Vide non ventilé 5 mm',
    SEVEN: 'Vide non ventilé 7 mm',
    TEN: 'Vide non ventilé 10 mm',
    FIFTEEN: 'Vide non ventilé 15 mm',
    BETWEEN_TWENTY_FIVE_AND_FIFTY: 'Vide non ventilé 25 ≤ d < 50',
    BETWEEN_FIFTY_AND_ONE_HUNDRED: 'Vide non ventilé 50 ≤ d < 100',
    BETWEEN_ONE_HUNDRED_AND_THREE_HUNDRED: 'Vide non ventilé 100 ≤ d < 300',
    THREE_HUNDRED: 'Vide non ventilé 300 mm',
});

export const wasteTypeEnum = Object.freeze({
    INERT_WASTE: 'INERT_WASTE',
    NOT_DANGEROUS_AND_NOT_INERT_WASTE: 'NOT_DANGEROUS_AND_NOT_INERT_WASTE',
    DANGEROUS_WASTE: 'DANGEROUS_WASTE',
    SPECIFIC_WASTE: 'SPECIFIC_WASTE',
});

export const wasteTypeTranslations = Object.freeze({
    INERT_WASTE: 'Déchets inertes',
    NOT_DANGEROUS_AND_NOT_INERT_WASTE: 'Déchets non dangereux et non inertes',
    DANGEROUS_WASTE: 'Déchets dangereux',
    SPECIFIC_WASTE: 'Déchets spécifiques',
});

export const wasteManagementUnitsEnum = Object.freeze({
    LITER: 'LITER',
    UNIT: 'UNIT',
    TONNE: 'TONNE',
    METER: 'METER',
    SQUARE_METER: 'SQUARE_METER',
    CUBIC_METER: 'CUBIC_METER',
});

export const wasteManagementUnitsTranslation = Object.freeze({
    METER: 'm',
    SQUARE_METER: 'm²',
    CUBIC_METER: 'm³',
    LITER: 'litres',
    UNIT: 'unités',
    TONNE: 'tonnes',
});

export const invoiceTypes = Object.freeze({
    ADVANCE_PAYMENT: 'ADVANCE_PAYMENT',
    CLASSIC: 'CLASSIC',
    INVOICE_CREDIT_NOTE: 'INVOICE_CREDIT_NOTE',
});

export const woodHeatingType = Object.freeze({
    COMBUSTIBLE_SOUS_FORME_GRANULES: 'COMBUSTIBLE_SOUS_FORME_GRANULES',
    COMBUSTIBLE_SOUS_AUTRE_FORME_GRANULES: 'COMBUSTIBLE_SOUS_AUTRE_FORME_GRANULES',
});

export const woodHeatingTypesLabel = Object.freeze({
    COMBUSTIBLE_SOUS_FORME_GRANULES: 'Combustible sous forme de granulés',
    COMBUSTIBLE_SOUS_AUTRE_FORME_GRANULES: 'Combustible sous une autre forme que des granulés',
});

export const productFileTypes = Object.freeze({
    GREEN_FLAME_LABEL_SEVEN: 'GREEN_FLAME_LABEL_SEVEN',
    CERTIFICATION: 'CERTIFICATION',
});

export const productFileTypesLabel = Object.freeze({
    GREEN_FLAME_LABEL_SEVEN: 'Label flamme verte 7*',
    CERTIFICATION: 'Certfication',
});

export const coupDePouceTypes = Object.freeze({
    INSULATION: 'INSULATION',
    HEATING: 'HEATING',
    RENOVATION_GLOBAL: 'RENOVATION_GLOBAL',
    CONNECTED_HEATING_CONTROL: 'CONNECTED_HEATING_CONTROL',
});

export const coupDePouceTypesTranslations = Object.freeze({
    INSULATION: 'Isolation',
    HEATING: 'Chauffage',
    RENOVATION_GLOBAL: "Rénovation performante d'une maison individuelle",
    CONNECTED_HEATING_CONTROL: 'Pilotage connecté du chauffage pièce par pièce',
});

export const rackingProfiles = Object.freeze({
    M: 'M',
    L: 'L',
    XL: 'XL',
});

export const rackingProfilesLabels = Object.freeze({
    M: 'M (95% d’efficacité énergétique)',
    L: 'L (100% d’efficacité énergétique)',
    XL: 'XL (110% d’efficacité énergétique)',
});

export const delegateeInvoiceProductTypes = Object.freeze({
    DISBURSEMENT_NOTICE: 'DISBURSEMENT_NOTICE',
    SERVICE: 'SERVICE',
});

export const delegateeInvoiceProductTypesTranslations = Object.freeze({
    DISBURSEMENT_NOTICE: 'Note de débours',
    SERVICE: 'Prestation de service',
});

export const mandatMprMixteEvents = Object.freeze({
    ORGANIZATION_MANDAT_MPR_MIXTE_ACTIVATED: 'ORGANIZATION_MANDAT_MPR_MIXTE_ACTIVATED',
    ORGANIZATION_MANDAT_MPR_MIXTE_DEACTIVATED: 'ORGANIZATION_MANDAT_MPR_MIXTE_DEACTIVATED',
});

export const billingDelayPaymentTypes = Object.freeze({
    AT_INVOICE_RECEPTION: 'AT_INVOICE_RECEPTION',
    AT_DEADLINE: 'AT_DEADLINE',
});

export const functioningAlimentationTypes = Object.freeze({
    AUTOMATIC_ALIMENTATION_PELLET_BOILER: 'AUTOMATIC_ALIMENTATION_PELLET_BOILER',
    MANUEL_ALIMENTATION_FUEL_BOILER: 'MANUEL_ALIMENTATION_FUEL_BOILER',
});

export const functioningAlimentationTypesLabel = Object.freeze({
    AUTOMATIC_ALIMENTATION_PELLET_BOILER: 'Alimentation automatique – chaudières à granulés',
    MANUEL_ALIMENTATION_FUEL_BOILER: 'Alimentation manuelle – chaudières à bûches',
});

export const heatingEquipmentFunctioningTypes = Object.freeze({
    CHARCOAL: 'CHARCOAL',
    FUEL: 'FUEL',
    GAS: 'GAS',
});

export const heatingEquipmentFunctioningTypesTranslations = Object.freeze({
    CHARCOAL: 'au charbon',
    FUEL: 'au fioul',
    GAS: 'au gaz',
});

export const removalOldBoilerProductsByHeatingEquipmentFunctioning = Object.freeze({
    CHARCOAL: 'REMOVAL_OLD_CHARCOAL_BOILER',
    FUEL: 'REMOVAL_OLD_FUEL_BOILER',
    GAS: 'REMOVAL_OLD_GAS_BOILER',
});

export const brandOfProducts = Object.freeze({
    CHAUFFE_EAU_THERMODYNAMIQUE: ['ALTECH', 'ATLANTIC', 'AUER', 'AIRWELL', 'THALEOS', 'DE DIETRICH', 'HITACHI', 'THERMOR', 'CHAFFOTEAUX', 'VIESSMANN', 'MIDEA', 'ARISTON'],
    POMPE_CHALEUR: {
        POMPE_CHALEUR_AIR_EAU: [
            'AIRWELL',
            'ARISTON',
            'ARKTEOS',
            'ATLANTIC',
            'AUER',
            'BOSCH',
            'CHAFFOTEAUX',
            'DAIKIN',
            'DE DIETRICH',
            'GREE',
            'HEIWA',
            'HITACHI',
            'LASIAN',
            'LG',
            'MAZDA',
            'MIDEA',
            'MITSUBISHI',
            'NEXTHERM',
            'PANASONIC',
            'PERGE',
            'SAMSUNG',
            'SAUNIER DUVAL',
            'STIEBEL ELTRON',
            'TOSHIBA',
            'VAILLANT',
            'ZAEGEL HELD',
            'VIESSMANN',
        ],
        POMPE_CHALEUR_EAU_EAU: ['ATLANTIC', 'HITACHI', 'NEXTHERM'],
        POMPE_CHALEUR_SOL_EAU: ['ATLANTIC', 'HITACHI', 'NEXTHERM'],
        POMPE_CHALEUR_HYBRIDE: ['ATLANTIC', 'DAIKIN', 'DE DIETRICH', 'PERGE'],
        POMPE_CHALEUR_AIR_AIR: ['ATLANTIC', 'BOSCH', 'DAIKIN', 'HEIWA', 'HITACHI', 'MIDEA', 'MITSUBISHI', 'PANASONIC', 'THERMOR', 'TOSHIBA'],
    },
    ISOLANT: [
        'ACTIS',
        'ISOVER',
        'URSA',
        'KNAUF INSULATION',
        'ISO 2000',
        'STEICO',
        'ISOL FRANCE',
        'HIRSCH',
        'WEBER',
        'STO',
        'POLYPROD',
        'IGLOO',
        'UNILIN',
        'ROCKWOOL',
        'SOPREMA',
        'TERREAL',
        'ORION',
        'SWISSPORT',
        'SINIAT',
    ],
    CHAUFFE_EAU_SOLAIRE_INDIVIDUEL: ['SUNPACKS', 'ALLIANTZ', 'SOLIDRO', 'ORION', 'CHAPPEE', 'PLEION'],
    CHAUDIERE_INDIVIDUELLE: ['SAUNIER DUVAL', 'DE DIETRICH', 'OERTLI', 'FRISQUET', 'CHAPPEE'],
    VENTILATION: {
        SIMPLE_FLOW_VENTILATION: ['ATLANTIC'],
        DOUBLE_FLOW_VENTILATION: ['IDEO', 'ALDES', 'S&P', 'ECONOPRIME', 'BROFRPRA', 'UNELVENT', 'GECO'],
    },
    CALORIFUGEAGE: ['NMC TECHNICAL INSULATION', 'ARMACELL'],
    SYSTEME_SOLAIRE_COMBINE: ['HELIO FRANCE', 'ALLIANTZ', 'SOLIDRO', 'TOP-ENR', 'HELIOS', 'ESE SOLAR'],
    APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS: {
        POELE_GRANULES: ['INTERSTOVES', 'SKIA DESIGN', 'NORDICA EXTRA FLAME', 'EXTRAFLAME', 'THERMOROSSI', 'STOVE ITALIA', 'RED', 'ANSELMO COLA', 'ETNA', 'UNICAL', 'JOLLY MEC'],
        POELE_BUCHES: ['INTERSTOVES', 'INVICTA', 'FIREPLACE', 'SUPRA', 'GODIN'],
        FOYER_FERME_OU_INSERT_CHEMINEE_EXTERIEURE: ['BRONPI', 'SOLZAIMA'],
        CUISINIERE_UTILISEE_COMME_MODE_DE_CHAUFFAGE: ['GODIN'],
    },
    CHAUFFE_EAU_SOLAIRE: ['BIOME SOLAR INDUSTRY'],
    REGULATION_SYSTEM: ['HEATZY', 'SILUMEN', 'THALEOS', 'XANLITE', 'TADO', 'MAZDA', 'TRIOMPH'],
});

export const emailsStatuses = Object.freeze({
    MAIL_SENT: 'MAIL_SENT',
    MAIL_RECEIVED: 'MAIL_RECEIVED',
    MAIL_OPENED: 'MAIL_OPENED',
    MAIL_FAILED: 'MAIL_FAILED',
});

export const emailsStatusesTranslations = Object.freeze({
    MAIL_SENT: 'Envoyé',
    MAIL_RECEIVED: 'Reçu',
    MAIL_OPENED: 'Ouvert',
    MAIL_FAILED: 'Non remis',
});

export const contractNegotiators = Object.freeze({
    PRO: 'PRO',
    RENOLIB: 'RENOLIB',
});

export const dossierMaPrimeRenovDocumentTypes = Object.freeze({
    QUOTATION: 'QUOTATION',
    CADRE_CONTRIBUTION: 'CADRE_CONTRIBUTION',
    MAPRIME_RENOV_CERTIFICATE: 'MAPRIME_RENOV_CERTIFICATE',
    SOCIAL_LANDLORD_ATTESTATION: 'SOCIAL_LANDLORD_ATTESTATION',
    ANAH_GRANT_LETTER: 'ANAH_GRANT_LETTER',
    INVOICE: 'INVOICE',
    CONTROL_REPORT: 'CONTROL_REPORT',
    SITE_PHOTO: 'SITE_PHOTO',
    SITE_PHOTO_BEFORE: 'SITE_PHOTO_BEFORE',
    SITE_PHOTO_AFTER: 'SITE_PHOTO_AFTER',
});

export const dossierMaPrimeRenovDocumentTypeSubtitles = Object.freeze({
    QUOTATION: 'Signé par le client',
    MAPRIME_RENOV_CERTIFICATE: 'Signé par le client',
    SOCIAL_LANDLORD_ATTESTATION: 'Signé par le client',
    ANAH_GRANT_LETTER: 'Validée',
    INVOICE: 'Déposée sur le compte du client',
    CONTROL_REPORT: 'Conforme',
    SITE_PHOTO: 'Photo de site',
    PAYMENT_LETTER: 'Lettre de versement',
});

export const dossierMaPrimeRenovDocumentTypeTranslations = Object.freeze({
    QUOTATION: 'Devis des travaux',
    CADRE_CONTRIBUTION: 'Cadre(s) de contribution',
    MAPRIME_RENOV_CERTIFICATE: 'Mandat',
    SOCIAL_LANDLORD_ATTESTATION: 'Attestation du propriétaire bailleur',
    ANAH_GRANT_LETTER: "Lettre d'octroi",
    INVOICE: 'Facture des travaux',
    CONTROL_REPORT: 'Rapport de contrôle',
    SITE_PHOTO: 'Photo de site',
    SITE_PHOTO_AFTER: 'Photo de site',
    SITE_PHOTO_BEFORE: 'Photo de site',
    ANAH_PAYMENT_LETTER: 'Lettre de versement',
});

export const renolibEmails = Object.freeze({
    PRO_SUPPORT: 'support-pro@renolib.fr',
    TECH: 'tech@renolib.fr',
    PARTNER_SUPPORT: 'support-partenaire@renolib.fr',
});

export const quotationsAnnexes = Object.freeze({
    SWORN_STATEMENT: "Attestation sur l'honneur",
    CADRE_CONTRIBUTION: 'Cadre(s) de contribution',
    TAX_NOTICE: 'Attestation TVA',
    ADMINISTRATIVE_REPRESENTATIVE: 'Mandat administratif MPR',
    ADMINISTRATIVE_AND_FINANCIAL_REPRESENTATIVE: 'Mandat mixte MPR',
    FINANCIAL_REPRESENTATIVE: 'Mandat financier MPR',
    CLAIM_ASSIGNMENT: 'Lettre de cession de créance',
    SOCIAL_LANDLORD_ATTESTATION: 'Attestation du propriétaire bailleur',
    JOINT_OWNER_SHIP_CERTIFICATE: "Attestation d'indivision",
    CONSENT_CERTIFICATE: 'Attestation de consentement',
    ATTESTATION_PUBLIC_LIGHTNING: 'Attestation éclairage public',
    ATTESTATION_OF_PHOTOVOLTAIC_PANEL: 'Mandat panneau photovoltaïque',
});

export const attestationTypesTranslations = Object.freeze({
    ATTESTATION_CHANGE_OF_PRODUCT: 'Attestation de changement dʼéquipement/matériaux pour les travaux :',
    ATTESTATION_CHANGE_OF_SUBCONTRACTOR: 'Attestation de changement de sous-traitant pour les travaux :',
    ATTESTATION_PUBLIC_LIGHTNING: 'L’attestation d’installation de matériel éligible au CEE par le service interne',
});

export const commercialDiscountType = Object.freeze({
    PERCENTAGE: 'PERCENTAGE',
    VALUE: 'VALUE',
});

export const applicationConfigurationModes = Object.freeze({
    MAINTENANCE: 'MAINTENANCE',
    ONLINE: 'ONLINE',
});

export const operationTypes = Object.freeze({
    ENERGY_RENOVATION_OPERATION: 'ENERGY_RENOVATION_OPERATION',
    ENERGY_RENOVATION_SERVICE: 'ENERGY_RENOVATION_SERVICE',
    NON_ENERGY_RENOVATION_OPERATION: 'NON_ENERGY_RENOVATION_OPERATION',
    ENERGY_RENOVATION_WORK: 'ENERGY_RENOVATION_WORK',
});

export const quotationTypes = Object.freeze({
    ENERGY_RENOVATION_QUOTATION: 'ENERGY_RENOVATION_QUOTATION',
    OTHER_ENERGY_RENOVATION_QUOTATION: 'OTHER_ENERGY_RENOVATION',
});
export const energyRenovationServices = Object.freeze({
    ENERGETIC_AUDIT: 'ENERGETIC_AUDIT',
});

export const energyRenovationWorks = Object.freeze({
    PHOTOVOLTAIC_PANELS: 'PHOTOVOLTAIC_PANELS',
});

export const operationSaleTypes = Object.freeze({
    SALE: 'SALE',
    SALE_BY_SUBSCRIPTION: 'SALE_BY_SUBSCRIPTION',
});

export const operationSaleTypesTranslations = Object.freeze({
    SALE: 'Vente',
    SALE_BY_SUBSCRIPTION: 'Vente par abonnement',
});

export const productBackupEnergies = Object.freeze({
    ELECTRIC_JOULE: 'ELECTRIC_JOULE',
    OTHER: 'OTHER',
});

export const productBackupEnergiesTranslations = Object.freeze({
    ELECTRIC_JOULE: 'Electrique à effet Joule',
    OTHER: 'Autre',
});

export const uploadDocumentUploadSources = Object.freeze({
    CUSTOMER: 'CUSTOMER',
    ORGANIZATION: 'ORGANIZATION',
});

export const titlesCascaderTranslations = Object.freeze({
    brand: 'Marque',
    title: 'Produit',
    thickness: 'Épaisseur',
    pacNominalPower129: 'Puissance nominale',
    pacNominalPower104: 'Puissance nominale',
    nominalPower: 'Puissance nominale',
    operatingMode: 'Mode de fonctionnement',
    installationType: "Type d'installation",
    literCapacity: 'Capacité du ballon en litres',
    name: 'Mode de ventilation',
    subCategory: 'Sous catégorie',
});

export const operatingTypes = Object.freeze({ MEDIUM_TEMPERATURE: 'MEDIUM_TEMPERATURE', HIGH_TEMPERATURE: 'HIGH_TEMPERATURE' });

export const operatingTypesTranslations = Object.freeze({
    MEDIUM_TEMPERATURE: 'Moyenne température',
    HIGH_TEMPERATURE: 'Haute température',
});

export const productCascaderLabels = Object.freeze({
    MEDIUM_TEMPERATURE: 'Moyenne température',
    HIGH_TEMPERATURE: 'Haute température',
    'medium-high-temperature-heat-pump': 'Moyenne ou haute température',
    'low-temperature-heat-pump': 'Basse température',
    COMBUSTIBLE_SOUS_FORME_GRANULES: 'Forme de granulés',
    COMBUSTIBLE_SOUS_AUTRE_FORME_GRANULES: 'Autre forme que des granulés',
    AUTOMATIC_ALIMENTATION_PELLET_BOILER: 'Automatique - chaudière à granulés',
    MANUEL_ALIMENTATION_FUEL_BOILER: 'Manuelle - chaudière à bûches',
    INDIVIDUAL_INSTALLATION_SELF_ADJUSTING: 'Installation individuelle auto-réglable',
    INDIVIDUAL_INSTALLATION_MODULATED: 'Installation individuelle modulaire',
    COLLECTIVE_INSTALLATION_SELF_ADJUSTING: 'Installation collective auto-réglable',
    INDIVIDUAL_INSTALLATION: 'Installation individuelle',
    COLLECTIVE_INSTALLATION: 'Installation collective',
    ...photovoltaicPanelsSubCategoriesTranslation,
});

export const MIXED_MANDATE_TYPES = [representativeType.FINANCIAL_REPRESENTATIVE, representativeType.ADMINISTRATIVE_AND_FINANCIAL_REPRESENTATIVE];

export const customerPPFormTypes = {
    WITH_TAX_NOTICE: 'WITH_TAX_NOTICE',
    WITHOUT_TAX_NOTICE: 'WITHOUT_TAX_NOTICE',
};

export const heatingPumpUsages = Object.freeze({
    HEATING_ECS: 'HEATING_ECS',
    HEATING_ONLY: 'HEATING_ONLY',
});

export const heatingPumpUsagesTranslations = Object.freeze({
    HEATING_ECS: 'Chauffage et ECS',
    HEATING_ONLY: 'Chauffage',
});

export const buildingAges = Object.freeze({
    NEW_BUILDING: 'NEW_BUILDING',
    BUILDING_IS_OLDER_THAN_TWO_YEARS: 'BUILDING_IS_OLDER_THAN_TWO_YEARS',
    BUILDING_IS_OLDER_THAN_FIFTEEN_YEARS: 'BUILDING_IS_OLDER_THAN_FIFTEEN_YEARS',
});

export const DOCUMENT_CHECK_STATUSES = Object.freeze({
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
});

export const waterSavingSystemsClassesTranslations = Object.freeze({
    Z_CLASS: 'Classe Z (7,2 à 12 litres/minute) de la norme EN NF 1112 et avec l’exigence d’un débit maximum à 9 litres/minute à 3 bars de pression',
    ZZ_CLASS_OR_EPA_WATERSENSE: 'Classe ZZ de la norme EN NF 1112 ou EPA « Watersense » pour les débits inférieurs à 7,6 litres/minute',
    NON_REGULATED: 'Aérateurs non régulés de classe Z (7,5 à 9 litres/minute) de la norme EN NF 246',
    SELF_REGULATING:
        'Aérateurs auto-régulés de débit inférieur à 7,5 litres/minute des normes américaines ASME/ANSI A112.18.1 et NSF 61 et ayant obtenu le label « EPA Watersense » pour les débits inférieurs à 5,68 litres/mn (1,5 gallon/mn)',
});

export const asyncJobStatuses = Object.freeze({
    IN_PROGRESS: 'IN_PROGRESS',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
});

export const asyncJobScopes = Object.freeze({
    DOCUMENT_GENERATION: 'DOCUMENT_GENERATION',
    DOCUMENT_DELETION: 'DOCUMENT_DELETION',
});

export const operationDetailTypes = Object.freeze({
    NON_EXISTING_REGULATION_SYSTEM_INSTALLATION: 'NON_EXISTING_REGULATION_SYSTEM_INSTALLATION',
    EXISTING_REGULATION_SYSTEM_REPLACEMENT: 'EXISTING_REGULATION_SYSTEM_REPLACEMENT',
    REGULATION_SYSTEM_CLASS_A: 'REGULATION_SYSTEM_CLASS_A',
    REGULATION_SYSTEM_CLASS_B: 'REGULATION_SYSTEM_CLASS_B',
    REGULATION_SYSTEM_ELECTRIC_HEAT_EMITTER: 'REGULATION_SYSTEM_ELECTRIC_HEAT_EMITTER',
    REGULATION_SYSTEM_HYDRAULIC_HEAT_EMITTER: 'REGULATION_SYSTEM_HYDRAULIC_HEAT_EMITTER',
    ECO_WATT: 'ECO_WATT',
    ECO_GAS: 'ECO_GAS',
    ECO_GAS_WATT: 'ECO_GAS_WATT',
});

export const operationDetailTypesTranslations = Object.freeze({
    NON_EXISTING_REGULATION_SYSTEM_INSTALLATION: "L'achat et la mise en place d'un système neuf de régulation sur un système de chauffage ne disposant pas de régulation",
    EXISTING_REGULATION_SYSTEM_REPLACEMENT: "L'achat et la mise en place d'un système neuf de régulation en remplacement d'un système de régulation existant",
    REGULATION_SYSTEM_CLASS_A: 'Classe A',
    REGULATION_SYSTEM_CLASS_B: 'Classe B',
    REGULATION_SYSTEM_ELECTRIC_HEAT_EMITTER: 'Électrique',
    REGULATION_SYSTEM_HYDRAULIC_HEAT_EMITTER: 'Hydraulique',
    ECO_WATT: 'EcoWatt',
    ECO_GAS: 'EcoGaz',
    ECO_GAS_WATT: 'EcoGaz et EcoWatt',
    LABEL_CAPTOR_BRAND: 'Marque du capteur solaire',
    LABEL_CAPTOR_REFERENCE: 'Référence du capteur solaire',
    LABEL_SELECT_AUXILIARY_HEATING_MODE: "Mise en place d'un chauffage d'appoint",
});

export const alertTypes = Object.freeze({
    WARNING: 'warning',
});

export const documentUploadModes = Object.freeze({
    AUTOMATIC: 'AUTOMATIC',
    MANUAL: 'MANUAL',
});

export const operationErrorMessageScopes = Object.freeze({
    CONTRACT: 'CONTRACT',
});

export const identityVerificationTypes = Object.freeze({
    STANDARD_SIGNATURE: 'STANDARD_SIGNATURE',
    AVANCED_SIGNATURE: 'AVANCED_SIGNATURE',
    QUALIFIED_SIGNATURE: 'QUALIFIED_SIGNATURE',
});

export const sendingOptions = Object.freeze({
    WHATSAPP: 'WHATSAPP',
    EMAIL: 'EMAIL',
    COPY_LINK: 'COPY_LINK',
    START_ELECTRONIC_SIGNATURE: 'START_ELECTRONIC_SIGNATURE',
});

export const financingTypes = Object.freeze({
    NONE: 'NONE',
    PERSONAL: 'PERSONAL',
    CREDIT: 'CREDIT',
});

export const financingConsentRequestStatuses = Object.freeze({
    PENDING: 'PENDING',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
});

export const financingConsentRequestContractTypes = Object.freeze({
    CDI: 'CDI',
    CDD: 'CDD',
    PENSION: 'PENSION',
});

export const financingConsentRequestContractTypesTranslations = Object.freeze({
    CDI: 'CDI',
    CDD: 'CDD',
    PENSION: 'Retraite',
});

export const documentTypesToSign = Object.freeze({
    QUOTATION: 'Devis',
    CLAIM_ASSIGNMENT: 'Lettre de cession de créance',
    VAT_CERTIFICATE: 'Attestation de TVA',
    ALL_NECESSARY_QUOTATION_DOCUMENTS: 'Devis et annexes',
    SWORN_STATEMENT: 'Attestation sur l’honneur',
    BENEFICIARY_CONTROL_FORM: 'Formulaire de contrôle par contact bénéficiaire',
});

export const swornStatementStatuses = Object.freeze({
    UNSIGNED: 'UNSIGNED',
    SIGNED: 'SIGNED',
});

export const accountRoles = Object.freeze({
    ADMIN: 'ADMIN',
    OPERATOR: 'OPERATOR',
    COMMERCIAL_MANAGER: 'COMMERCIAL_MANAGER',
    COMMERCIAL: 'COMMERCIAL',
    ASSISTANT: 'ASSISTANT',
    TECHNICIAN: 'TECHNICIAN',
    SUPPORT: 'SUPPORT',
    CALL_CENTER: 'CALL_CENTER',
    RENOLIB_CONTROLLER: 'RENOLIB_CONTROLLER',
});

export const accountRolesTranslations = Object.freeze({
    ADMIN: 'Admin',
    OPERATOR: 'Opérateur',
    COMMERCIAL_MANAGER: 'Responsable commercial',
    COMMERCIAL: 'Commercial',
    ASSISTANT: 'Assistant',
    TECHNICIAN: 'Technicien',
    SUPPORT: 'Support',
    CALL_CENTER: 'Call Center',
});

export const MAPRIMERENOV_INSTALLATIONTYPE_OVERLOAD = {
    POMPE_CHALEUR_AIR_EAU: 'air/eau',
    POMPE_CHALEUR_HYBRIDE: 'hybride',
    POMPE_CHALEUR_AIR_AIR: 'air/air',
    POMPE_CHALEUR_SOL_EAU: 'sol/eau',
    POMPE_CHALEUR_EAU_EAU: 'eau/eau',
};

export const auxiliaryHeatingModesTranslations = Object.freeze({
    POMPE_CHALEUR_AIR_EAU: 'Pompe à chaleur air/eau',
    POMPE_CHALEUR_EAU_EAU_SOL_EAU: 'Pompe à chaleur eau/eau ou sol/eau',
    POMPE_CHALEUR_AIR_AIR: 'Pompe à chaleur air/air',
    POMPE_CHALEUR_HYBRIDE: 'Pompe à chaleur hybride individuelle',
    APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS: 'Appareil indépendant de chauffage au bois',
    CHAUDIERE_BIOMASSE_INDIVIDUELLE: 'Chaudière biomasse individuelle',
    KEEP_EXISTING: "Garder l'existant",
});

export const reflectiveIsolantTranslations = Object.freeze({
    IS_REFLECTIVE: 'Isolant réfléchissant (NF EN 16012+A1 ou NF EN ISO 22097)',
    IS_NOT_REFLECTIVE: 'Isolant non réfléchissant (NF EN 12664 ou NF EN 12667 ou NF EN 12939)',
});
